import { render, staticRenderFns } from "./PhoneNumber.vue?vue&type=template&id=494e5c34"
import script from "./PhoneNumber.vue?vue&type=script&lang=js"
export * from "./PhoneNumber.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VuetifyPhoneInput: require('/var/app/components/Vuetify/PhoneInput.vue').default})
